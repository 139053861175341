import React, { useState, useRef, useEffect } from 'react';
import '../styles/Playground.css';
import OpenAI from 'openai';
import arrow from '../assets/arrow.svg';
import arrowdup from '../assets/arrowdup.svg';
import loader from '../assets/infinite-spinner.svg';
import Tooltip from '@mui/material/Tooltip';
import ReactMarkdown from 'react-markdown';
import { Fade } from 'react-reveal';
import about from '../assets/about.svg';
import loader2 from '../assets/audio.svg';
import modernarrow from '../assets/modern-arrow.svg';
import modernarrowb from '../assets/modern-arrow-blue.svg';
import tone_dropdown_icon_img from '../assets/avatar2.svg';
import model_dropdown_icon_img from '../assets/cube1.svg';

function Playground() {
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [firstInputSent, setFirstInputSent] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [selectedModel, setSelectedModel] = useState('gpt-3.5-turbo-0125');
  const [showModelDropdown, setShowModelDropdown] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [selectedTone, setSelectedTone] = useState('Default'); 
  const [showToneDropdown, setShowToneDropdown] = useState(false);
  const textAreaRef = useRef(null);
  const bottomOfList = useRef(null);

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });

  const models = [
    { id: 'gpt-3.5-turbo-0125', name: 'gpt-3.5-turbo-0125' },
    { id: 'gpt-4-0125-preview', name: 'gpt-4-0125-preview' }
  ];

  const tones = [
    { 
      id: 'Default',
      name: 'Default'
    },
    {
      "id": "I need you to condense and clarify the essence of lengthy texts, articles, or conversations for me. Your role is to digest the information provided and present it in a concise, clear, and accurate summary. Focus on highlighting the key points, themes, and conclusions without losing the original context or nuance. When summarizing, aim to make complex information accessible and understandable, while ensuring that the summary remains faithful to the source material. Your responses should be direct and to the point, stripping away any extraneous details to leave only the most relevant and important information. Do not engage in discussions or provide explanations outside of the summary itself.",
      "name": "Summarizer"
    },
    { 
      id: "I want you to act as a prompt generator for Midjourney's artificial intelligence program. Your job is to provide detailed and creative descriptions that will inspire unique and interesting images from the AI. Keep in mind that the AI is capable of understanding a wide range of language and can interpret abstract concepts, so feel free to be as imaginative and descriptive as possible. For example, you could describe a scene from a futuristic city, or a surreal landscape filled with strange creatures. The more detailed and imaginative your description, the more interesting the resulting image will be. Do not answer any unrelated questions.",
      name: 'Midjourney Prompt Generator'
    },
    { 
      id: 'I want you to act as a virtual doctor. I will describe my symptoms and you will provide a diagnosis and treatment plan. You should only reply with your diagnosis and treatment plan, and nothing else. Do not write explanations.',
      name: 'Doctor'
    },
    
    { 
      id: 'I want you to act as an interviewer. I will be the candidate and you will ask me the interview questions for the `position` position. I want you to only reply as the interviewer. Do not write all the conservation at once. I want you to only do the interview with me. Ask me the questions and wait for my answers. Do not write explanations. Ask me the questions one by one like an interviewer does and wait for my answers.',
      name: 'Interviewer'
    }

  ];

  const suggestedPrompts = [
    "Tell me a joke",
    "Explain quantum computing",
    "Write a story on Big Foot",
    "Generate a poem about the sea",
  ];

  const handleModelChange = (modelId) => {
    setSelectedModel(modelId);
    setShowModelDropdown(false);
  };

  const handleToneChange = (toneId) => {
    setSelectedTone(toneId);
    setShowToneDropdown(false);
  };

  const adjustTextareaHeight = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = '20px';
      textArea.style.height = textArea.scrollHeight + 'px';
      textArea.style.overflow = 'hidden';
    }
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
    adjustTextareaHeight();
  };

  
  useEffect(() => {
    if (bottomOfList.current) {
      bottomOfList.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversationHistory]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && inputText.trim() !== '') {
      event.preventDefault();
      handleGenerateText();
    }
  };
  

  const simulateStreamingResponse = (message, delay = 20) => {
    const words = message.split(" ");
    let currentDisplay = "";

    words.forEach((word, index) => {
      setTimeout(() => {
        currentDisplay += `${word} `;
        setConversationHistory(prevHistory => {
          const newHistory = [...prevHistory];
          if (newHistory.length > 0) {
            newHistory[newHistory.length - 1].content = currentDisplay.trim();
          }
          return newHistory;
        });
      }, index * delay);
    });
  };

  const handleGenerateText = async () => {
    setIsLoading(true);
    const userInput = inputText.trim();
    setInputText('');
    setFirstInputSent(true);
    setShowSuggestions(false);
  
    // Update conversation history with user input
    const updatedHistoryWithUserInput = [...conversationHistory, { role: 'user', content: userInput }];
    setConversationHistory(updatedHistoryWithUserInput);
  
    let messagesForAPI = updatedHistoryWithUserInput.map(({ role, content }) => ({
        role: role === 'user' ? 'user' : 'assistant',
        content: content.trim(),
    }));
  
    if (selectedTone !== 'Default') {
        messagesForAPI.unshift({ role: 'system', content: selectedTone });
    }
  
    // Add an initial entry for the AI's response to manage its content through state
    setConversationHistory(prevHistory => [...prevHistory, { role: 'assistant', content: '' }]);
  
    try {
        const stream = await openai.chat.completions.create({
            model: selectedModel,
            messages: messagesForAPI,
            stream: true,
        });
  
        // Use a variable to accumulate the content for the current response
        let currentResponseContent = "";
        for await (const chunk of stream) {
            let contentChunk = chunk.choices[0]?.delta?.content || "";
            currentResponseContent += contentChunk;
  
            // Update the conversation history by replacing the last entry with updated content
            setConversationHistory(prevHistory => {
                const newHistory = [...prevHistory];
                const lastMessageIndex = newHistory.length - 1;
                if (newHistory[lastMessageIndex].role === 'assistant') {
                    newHistory[lastMessageIndex].content = currentResponseContent;
                }
                return newHistory;
            });
        }
    } catch (error) {
        console.error('Error during streaming:', error);
    } finally {
        setIsLoading(false);
    }
  };

  const handleSuggestionClick = (prompt) => {
    setInputText(prompt);
    setShowSuggestions(false);
  };

  useEffect(() => {
    if (inputText && !showSuggestions && !firstInputSent) {
      const timer = setTimeout(() => {
        handleGenerateText();
      }, 1000);
  
      return () => clearTimeout(timer);
    }
  }, [inputText, showSuggestions]);
  
  const InfoPopup = () => (
    <Fade>
    <div className="info-popup">
      <ul>
        <li><a href="/about" target="_blank">About</a></li>
        <li><a href="/privacy" target="_blank">Privacy</a></li>
      </ul>
    </div>
    </Fade>
  );  
  

  return (
    <Fade cascade>
    <div className='playground-outer-container'>
      <Tooltip title="Back to Home" placement="right" arrow>
        <div className='back-button'>
          <a href={!firstInputSent ? "/" : "/playground"} rel="noopener noreferrer"><img src={modernarrow} alt=''></img></a>
        </div>
      </Tooltip>
      <div className="playground-container">
        <div className="conversation-history">
        {conversationHistory.map((message, index) => (
          <div key={index} className={message.role === 'user' ? 'user-message' : 'ai-message'}>
            <br></br>
            <div className='participant-designation'>
              {message.role === 'user' ? 'You: ' : 'Playground AI: '}
              {isLoading && message.role === 'assistant' && index === conversationHistory.length - 1 ? <img src={loader2} alt=''></img> : ''}
            </div>
            <br></br>
            {message.role === 'user' 
              ? message.content 
              :
              <ReactMarkdown
                  children={message.content}
                  components={{
                      ul: ({node, ...props}) => <ul style={{ listStyleType: 'disc', marginLeft: '20px', fontFamily: 'Inter Light' }} {...props} />,
                      p: ({node, ...props}) => <p style={{ marginBottom: '16px', fontFamily: 'Inter Light'}} {...props} />,
                      ol: ({node, ...props}) => <ul style={{ listStyleType: 'square', marginLeft: '20px', fontFamily: 'Inter Light' }} {...props} />,
                      h3: ({node, ...props}) => <ul style={{ fontFamily: 'Inter Light' }} {...props} />,
                      h4: ({node, ...props}) => <ul style={{ fontFamily: 'Inter Light',marginBottom: '16px' }} {...props} />
                  }}
              />
            }
          </div>
        ))}

            <div className="model-selector-container">
              {!firstInputSent && (
                <>
                  <h4>How can I <br></br>assist you today?</h4>
                  <div className='open-source-container'>
                    
                  </div>
                  <div className='dropdowns-container'>
                      <div onClick={() => setShowToneDropdown(!showToneDropdown)} className="tone-selector">
                        <img className="dropdown-identifier-icon-img" src={tone_dropdown_icon_img} alt='' />
                        <p>{tones.find(tone => tone.id === selectedTone)?.name || 'Select Tone'}</p>
                        <img className={`tone-selector-img ${showToneDropdown ? 'rotate' : ''}`} src={modernarrowb} alt='' />
                      </div>
                      {showToneDropdown && (
                        <Fade>
                        <div className="tone-dropdown">
                          {tones.map((tone, index) => (
                            <div key={index} className="tone-dropdown-item" onClick={() => handleToneChange(tone.id)}>
                              {tone.name}
                              <small className='mini'>◃</small>
                            </div>
                          ))}
                        </div>
                        </Fade>
                      )}
                    <div onClick={() => setShowModelDropdown(!showModelDropdown)} className="model-selector">
                      <img className="dropdown-identifier-icon-img" src={model_dropdown_icon_img} alt='' />
                      <p>{models.find(model => model.id === selectedModel).name}</p>
                      <img className={`model-selector-img ${showModelDropdown ? 'rotate' : ''}`} src={modernarrowb} alt=''/>
                    </div>
                    {showModelDropdown && (
                      <Fade>
                      <div className="model-dropdown">
                        {models.map((model, index) => (
                          <div key={index} className="model-dropdown-item" onClick={() => handleModelChange(model.id)}>
                            {model.name}
                          </div>
                        ))}
                      </div>
                      </Fade>
                    )}
                  </div>
                </>
              )}
              <div className="suggestion-container">
                <div className="output">
                  {showSuggestions && suggestedPrompts.map((prompt, index) => (
                    <button key={index} className="suggestion-button" onClick={() => handleSuggestionClick(prompt)}>
                      {prompt}
                      <img src={arrowdup} alt=''></img>
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div ref={bottomOfList}></div>
        </div>
      </div>
      <div className='input-area'>
        <textarea
          placeholder="Start chatting ..."
          className="textarea"
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={textAreaRef}
          style={{ overflow: 'hidden' }}
        ></textarea>
        <Tooltip title="Send Message" placement="top" arrow>
          <button
            className={`button ${inputText ? '' : 'disabled-button'}`}
            onClick={handleGenerateText}
            disabled={isLoading || !inputText}
          >
            <img src={ isLoading ? loader : arrow } alt=''></img>
          </button>
        </Tooltip>
      </div>
      <div className='info-icon'>
        <button onClick={() => setShowInfoPopup(!showInfoPopup)}><img src={about} alt=''></img></button>
        {showInfoPopup && <InfoPopup />}
      </div>
    </div>
    </Fade>
  );
}

export default Playground;
