import React from 'react';
import '../styles/projects.css';
import { Fade } from 'react-reveal';

const Projects = () => {
    return (
    <Fade delay={1250} cascade>
      <div  className='projects_container'>
        <h6>View My Projects</h6>
        <div className='project_buttons_container'>
            <a href='/playground' target='_blank'>AI Playground</a>
            <a href='https://main.dchg4g93vqfui.amplifyapp.com/' target='_blank'>Calculator</a>
        </div>
      </div>
      </Fade>
    );
  };
  
export default Projects;
