import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Privacy from "./components/Privacy";
import Playground from './components/Playground';
import Projects from './components/Projects';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  return (
    <>
      <div className="outer-container">
        <div className="inner-container">
          <div className="content-container">
            <div className="flex-container">
              {/* Conditionally render Header */}
              {location.pathname !== '/privacy' && location.pathname !== '/playground' && <Header />}
              <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/playground" element={<Playground />} />
                <Route path="/" element={<Projects />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
