import React from 'react';
import '../styles/footer.css';

const Footer = () => {

    return (
      <>
        <small>Copyright © 2024   ·   Shaar Haq  ·   Built in React on AWS</small>
      </>
    );
  };
  
export default Footer;