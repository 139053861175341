import React from 'react';
import '../styles/privacy.css';

const Privacy = () => {
    return (
      <>
        <div class='privacy-container'>
        <h1>
            Privacy Policy
        </h1>
        <p>If you've found this link, you've likely discovered one of my apps on the Apple App Store or by using my <a href="/playground" rel='no_referrer' >AI Playground</a>. I want to assure you that my applications do not collect any data or employ third-party identifiers. Your privacy is of utmost importance, and I prioritize maintaining strict privacy standards in all my app developments. Rest assured, your data remains secure and is not shared with any external entities. Thank you for your trust and support.</p>
            <a href='/'><button className='privacy-button'>Go Back</button></a>
        </div>
      </>
    );
  };
  
export default Privacy;